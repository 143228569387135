import React from "react"
import styled from "styled-components"

const StyledCluster = styled.div`
	--space: 1rem;
	overflow: hidden;

	& > * {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		margin: calc(var(--space) / 2 * -1);
	}

	& > * > * {
		margin: calc(var(--space) / 2);
	}
`

export const Cluster = ({ children, ...props }) => {
	return <StyledCluster {...props}>{children}</StyledCluster>
}
