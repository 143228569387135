import React from "react"
import styled from "styled-components"
import { fontWeight, spacingProps } from "../cssProps"

export const StyledText = styled.p`
	${fontWeight};
	${spacingProps};
	font-size: ${(props) => props.theme.fontSizes[props.fontSize]};
	color: ${(props) => props.theme.colors[props.color][props.shade]};
	text-align: ${(props) => props.align};
	max-width: 69ch;
`

export const Text = ({ children, ...props }) => {
	return <StyledText {...props}>{children}</StyledText>
}

Text.defaultProps = {
	fontWeight: "regular",
	fontSize: 300,
	color: "neutral",
	shade: "dark"
}
