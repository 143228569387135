import styled from "styled-components"

export const StyledMenu = styled.nav`
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: ${({ theme }) => theme.colors.primary.default};
	height: 100vh;
	text-align: left;
	padding: 3.2rem;
	position: fixed;
	z-index: 9;
	top: 0;
	right: 0;
	transition: transform 0.3s ease-in-out;
	transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};

	@media (max-width: 800px) {
		width: 80%;
	}

	& + .backdrop {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 8;
		height: 100vh;
		background: rgba(0, 0, 0, 0.4);
		transition: transform 0.26s ease-out;
		transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};

		@media (max-width: 800px) {
			width: 100%;
		}
	}

	a {
		font-size: ${(props) => props.theme.fontSizes[props.fontSize]};
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: 0.8rem;
		color: ${({ theme }) => theme.colors.secondary.light};
		text-decoration: none;
		transition: color 0.3s linear;

		@media (max-width: 800px) {
			text-align: center;
		}

		&:hover {
			color: ${({ theme }) => theme.colors.secondary.t1};
		}
	}
`
