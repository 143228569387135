import React from "react"
import styled from "styled-components"
import { Text } from "../ziibo"
import logoWhite from "../images/logo-white.png"

const StyledFooter = styled.footer`
	width: 100%;
	padding: 4.8rem 0;
	background-color: ${(props) => props.theme.colors["neutral"]["dark"]};
`
const Footer = () => {
	return (
		<StyledFooter>
			<div style={{ display: "flex", justifyContent: "center", paddingBottom: "1.2rem" }}>
				<img src={logoWhite} alt="logo white" style={{ height: "6rem" }} />
			</div>
			<div style={{ display: "flex", justifyContent: "center", paddingBottom: "2.4rem" }}>
				<a href="https://www.twitter.com/alongtheseams" style={{ marginRight: "1.6rem" }}>
					<Text shade="light">TWITTER</Text>
				</a>
				<a href="https://www.instagram.com/alongtheseams_" style={{ marginRight: "1.6rem" }}>
					<Text shade="light">INSTAGRAM</Text>
				</a>
				{/* <a href="https://www.anchor.fm/alongtheseams">
					<Text shade="light">ANCHOR.FM</Text>
				</a> */}
				<a href="https://open.spotify.com/show/4Yw8Fnf64y3DPjmheN0btx">
					<Text shade="light">SPOTIFY</Text>
				</a>
			</div>
			<div style={{ textAlign: "center", width: "100%" }}>
				<Text fontSize={200} style={{ margin: "0 auto" }} shade="light">
					&copy; {new Date().getFullYear()} Along the Seams. All Rights Reserved.
				</Text>
				<Text fontSize={200} style={{ margin: "0 auto" }} shade="light">
					Built by <a href="https://www.grayhaber.com">Grayhaber</a> in CT.
				</Text>
			</div>
		</StyledFooter>
	)
}

export { Footer }
