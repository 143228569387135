import React from "react"
import styled from "styled-components"

const StyledImpostor = styled.div`
	/* ↓ Position the top left corner in the center */
	position: var(--positioning, absolute);
	top: 50%;
	left: 50%;
	/* ↓ Reposition so the center of the element
  is the center of the positioning container */
	transform: translate(-50%, -50%);
	max-width: calc(100% - 2rem);
	max-height: calc(100% - 2rem);
`

export const Impostor = ({ children, ...props }) => {
	return <StyledImpostor {...props}>{children}</StyledImpostor>
}
