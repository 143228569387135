import React from "react"
import { StyledMenu } from "./Menu.styled"
import { Link } from "gatsby"

const Menu = ({ open }) => {
	return (
		<>
			<StyledMenu open={open} fontSize={600}>
				<ul style={{ listStyle: "none", marginLeft: "2.4rem" }}>
					<li style={{ marginBottom: "2.4rem" }}>
						<Link to="/blog">Blog</Link>
					</li>
					<li style={{ marginBottom: "2.4rem" }}>
						<Link to="/podcast">Podcast</Link>
					</li>
					<li>
						<Link to="/about">About</Link>
					</li>
				</ul>
			</StyledMenu>
			<div className="backdrop"></div>
		</>
	)
}

export { Menu }
