import React from "react"
import styled from "styled-components"

const StyledCover = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	& > h1 {
		margin-top: auto;
		margin-bottom: auto;
	}

	& > :first-child:not(h1) {
		margin-top: 0;
	}

	& > :last-child:not(h1) {
		margin-bottom: 0;
	}
`

export const Cover = ({ children, ...props }) => {
	return <StyledCover {...props}>{children}</StyledCover>
}
