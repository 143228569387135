import React from "react"
import PropTypes from "prop-types"
// import { Cluster, Content } from "../bruin"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import { ThemeProvider } from "styled-components"
// import { GlobalStyle } from "../ziibo/GlobalStyle"
import { theme } from "../ziibo/theme"
import { Footer } from "./footer"
// import logo from "../images/logo-26.svg"
// import { Link } from "gatsby"

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)

	return (
		<ThemeProvider theme={theme}>
			{/* <GlobalStyle /> */}
			<Header siteTitle={data.site.siteMetadata?.title || `Title`} />
			{/* <Header contain>
				<Content>
					<Cluster>
						<div>
							<Link to="/">
								<img src={logo} alt="logo" />
							</Link>
							<Cluster style={{ justifyContent: "flex-start" }}>
								<ul style={{ listStyle: "none" }}>
									<Link to="/">
										<li>home</li>
									</Link>
									<Link to="/testing">
										<li>testing</li>
									</Link>
									<Link to="/foundation">
										<li>foundation</li>
									</Link>
									<Link to="/hubspot">
										<li>hubspot</li>
									</Link>
									<Link to="/monday">
										<li>monday</li>
									</Link>
									<Link to="/zendesk">
										<li>zendesk</li>
									</Link>
								</ul>
							</Cluster>
						</div>
					</Cluster>
				</Content>
			</Header> */}
			<div>
				<main>{children}</main>
			</div>
			<Footer />
		</ThemeProvider>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired
}

export default Layout
