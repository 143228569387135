import { colors } from "./colors"
import { spaces } from "./spaces"
import { elevations } from "./elevations"
import { textSizes, headingSizes, fontSizes, fontWeight, lineHeights } from "./fonts"
export const theme = {
	colors,
	spaces,
	textSizes,
	headingSizes,
	fontSizes,
	fontWeight,
	lineHeights,
	elevations
}
