import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"
import { Burger } from "./Burger"
import { Menu } from "./Menu"
import logo from "../images/logo.png"
import styled from "styled-components"
export const useOnClickOutside = (ref, handler) => {
	useEffect(() => {
		const listener = (event) => {
			if (!ref.current || ref.current.contains(event.target)) {
				return
			}
			handler(event)
		}
		document.addEventListener("mousedown", listener)
		return () => {
			document.removeEventListener("mousedown", listener)
		}
	}, [ref, handler])
}

const StyledHeader = styled.header`
	position: relative;
	width: 100%;
	height: 8rem;
	border-bottom: 1px solid #e3e6ea;
	background-color: white;
	box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 8px, rgba(67, 90, 111, 0.47) 0px 5px 7px -6px;
`

const StyledHeaderContent = styled.div`
	width: min(80vw, 120rem);
	margin: 0 auto;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const StyledNavList = styled.ul`
	display: flex;
	list-style: none;
	align-items: center;

	& li:not(:last-child) {
		padding-right: 2.4rem;
	}
`

// const StyledButton = styled.button`
// 	/* background: ${(props) => props.theme.colors.primary.default}; */
// 	background: #39b54a;
// 	text-transform: uppercase;
// 	text-decoration: none;
// 	font-weight: 700;
// 	letter-spacing: 0.05em;
// 	font-size: 1.4rem;
// 	display: inline-block;
// 	padding: 1.25rem 3rem;
// 	border: none;
// 	-webkit-transition: all 0.2s ease;
// 	transition: all 0.2s ease;
// 	cursor: pointer;
// 	border-radius: 3px;
// `

const Header = () => {
	const [open, setOpen] = useState(false)
	const node = useRef()
	useOnClickOutside(node, () => setOpen(false))

	const [windowDimension, setWindowDimension] = useState(null)

	useEffect(() => {
		setWindowDimension(window.innerWidth)
	}, [])

	useEffect(() => {
		function handleResize() {
			setWindowDimension(window.innerWidth)
		}

		window.addEventListener("resize", handleResize)
		return () => window.removeEventListener("resize", handleResize)
	}, [])

	const isMobile = windowDimension <= 768

	return (
		<StyledHeader ref={node}>
			<StyledHeaderContent>
				<Link to="/">
					<img src={logo} alt="logo" style={{ height: "5rem" }} />
				</Link>
				{isMobile ? (
					<>
						<Burger open={open} setOpen={setOpen} />
						<Menu open={open} setOpen={setOpen} />
					</>
				) : (
					<StyledNavList>
						<li>
							<Link to="/blog">Blog</Link>
						</li>
						<li>
							<Link to="/podcast">Podcast</Link>
						</li>
						<li>
							<Link to="/about">About</Link>
						</li>
					</StyledNavList>
				)}
			</StyledHeaderContent>
		</StyledHeader>
	)
}

Header.propTypes = {
	siteTitle: PropTypes.string
}

Header.defaultProps = {
	siteTitle: ``
}

export default Header
