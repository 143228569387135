import React from "react"
import styled from "styled-components"
import { spacingProps } from "../cssProps"

const StyledBox = styled.div`
	${spacingProps};
	box-shadow: ${(props) => props.theme.elevations[props.elevation]};
	${(props) => ({
		backgroundColor: props.theme.colors[props.bg][props.shade],
		height: props.height,
		width: props.width,
		border: props.border
	})}
`
export const Box = ({ children, ...props }) => {
	return <StyledBox {...props}>{children}</StyledBox>
}

Box.defaultProps = {
	pad: "none",
	bg: "neutral",
	shade: "none"
}
