import React from "react"
import styled from "styled-components"

const StyledFrame = styled.div`
	--n: 9; /* height */
	--d: 16; /* width */
	@media (orientation: portrait) {
		--n: 1;
		--d: 1;
	}
	padding-bottom: calc(var(--n) / var(--d) * 100%);
	position: relative;

	& > * {
		overflow: hidden;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	& > img,
	& > video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`

export const Frame = ({ children, ...props }) => {
	return <StyledFrame {...props}>{children}</StyledFrame>
}
