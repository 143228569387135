import React from "react"
import styled from "styled-components"

const StyledContent = styled.div`
	width: min(80vw, 120rem);
	margin: 0 auto;
`

export const Content = ({ children, ...props }) => {
	return <StyledContent {...props}>{children}</StyledContent>
}
