import React from "react"
import styled from "styled-components"
// import { spacing } from "../utils/spacing"

const StyledStack = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	&:only-child {
		height: 100%;
	}
	& > * + * {
		margin-top: 2.4rem;
	}

	/* & > :nth-child(2) {
		margin-bottom: auto;
	} */
`

export const Stack = ({ children, ...props }) => {
	return <StyledStack {...props}>{children}</StyledStack>
}
