export const colors = {
	primary: {
		light: "#d3d6e1",
		t2: "#9099b4",
		t1: "#4d5c87",
		default: "#213369",
		s1: "#1e2e5f",
		s2: "#17244a",
		dark: "#141f3f"
	},

	secondary: {
		light: "#fad2d7",
		t2: "#f28f9b",
		t1: "#e94c5e",
		default: "#E41F36",
		s1: "#b6192b",
		s2: "#891320",
		dark: "#440910"
	},

	tertiary: {
		light: "#EDE9DA",
		t2: "#EDE9DA",
		t1: "#DED1B7",
		default: "#C7AE82",
		s1: "#BA9962",
		s2: "#A78148",
		dark: "#A78148"
	},

	neutral: {
		light: "#C4C8D7",
		t2: "#C4C8D7",
		t1: "#9EA3BF",
		default: "gray",
		s1: "#596092",
		s2: "#434875",
		dark: "#434875"
	}
}
