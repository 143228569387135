import React from "react"
import styled from "styled-components"
import { spacing } from "../utils/spacing"
const StyledCenter = styled.div`
	box-sizing: content-box;
	max-width: 96ch;
	margin-left: auto;
	margin-right: auto;
	padding-left: ${(props) => props.pl && `${spacing(props.pl)}rem`};
	padding-right: ${(props) => props.pr && `${spacing(props.pr)}rem`};
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const Center = ({ children, ...props }) => {
	return <StyledCenter {...props}>{children}</StyledCenter>
}
