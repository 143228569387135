import React from "react"
import styled from "styled-components"
import { Box } from "./Box"

const StyledFlex = styled(Box)`
	display: flex;
	flex-direction: ${(props) => (props.direction ? props.direction : "row")};
	width: ${(props) => (props.width ? props.width : "1140px")};
	height: ${(props) => (props.height ? props.height : "auto")};
	margin: 0 auto;
	justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "space-evenly")};
	flex-wrap: ${(props) => (props.wrap ? props.wrap : "wrap")};
`

export const Flex = ({ children, ...props }) => {
	return <StyledFlex {...props}>{children}</StyledFlex>
}

Flex.defaultProps = {
	pad: "none",
	bg: "neutral",
	shade: "none",
	direction: "column"
}
