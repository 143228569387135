import React from "react"
import styled from "styled-components"
// import { spacing } from "../utils/spacing"

const StyledSwitcher = styled.div`
	/* --threshold: ${(props) => props.threshold ?? `60rem`}; */
	--threshold: 30rem;
	--space: 1rem;
	& > * {
		display: flex;
		flex-wrap: wrap;
		margin: calc((var(--space) / 2) * -1);
	}

	& > * > * {
		flex-grow: 1;
		flex-basis: calc((var(--threshold) - (100% - var(--space))) * 999);
		margin: calc(var(--space) / 2);
	}

	& > * > :nth-last-child(n + 5),
	& > * > :nth-last-child(n + 5) ~ * {
		flex-basis: 100%;
	}
`
export const Switcher = ({ children, ...props }) => {
	return <StyledSwitcher {...props}>{children}</StyledSwitcher>
}
