import { css } from "styled-components"
import { spacing } from "../utils/spacing"

export const spacingProps = css((props) => ({
	padding: props.pad && `${spacing(props.pad)}rem`,
	paddingTop: props.pt && `${spacing(props.pt)}rem`,
	paddingRight: props.pr && `${spacing(props.pr)}rem`,
	paddingBottom: props.pb && `${spacing(props.pb)}rem`,
	paddingLeft: props.pl && `${spacing(props.pl)}rem`,
	margin: props.mar && `${spacing(props.mar)}rem`,
	marginTop: props.mt && `${spacing(props.mt)}rem`,
	marginRight: props.mr && `${spacing(props.mr)}rem`,
	marginBottom: props.mb && `${spacing(props.mb)}rem`,
	marginLeft: props.ml && `${spacing(props.ml)}rem`
}))
