import React from "react"
import styled from "styled-components"
import { fontWeight, spacingProps } from "../cssProps"

const StyledHeading = styled.h2`
	${fontWeight};
	${spacingProps};
	font-size: ${(props) => props.theme.fontSizes[props.fontSize]};
	color: ${(props) => props.theme.colors[props.color][props.shade]};
	text-align: ${(props) => props.align};
	line-height: ${(props) => props.lineHeight && props.theme.lineHeights[props.lineHeight]};
`

export const Heading = ({ children, ...props }) => {
	return <StyledHeading {...props}>{children}</StyledHeading>
}

Heading.defaultProps = {
	fontWeight: "bold",
	fontSize: 600,
	color: "neutral",
	shade: "dark"
}
